@import 'src/styles/responsive';

.room-schedule.e-schedule {
  .e-month-view .e-appointment .e-appointment-details {
    padding: 1px;
    padding-left: 3px;
  }

  .e-vertical-view {
    .e-resource-cells {
      height: 130px;
      padding: 0;
    }

    .e-left-indent {
      .e-all-day-cells {
        display: none;
      }
    }

    .e-current-time {
      color: var(--black);
      font-size: 16px;
      font-weight: 500;
    }

    .e-work-cells {
      height: 40px;
      border-bottom-style: dashed;
      border-top-style: dashed;
      background-color: var(--white);
    }

    .e-selected-cell {
      opacity: 0.1;
      background-color: black;
    }

    .e-disabled-cell {
      opacity: 1 !important;
      pointer-events: none;
      border: none;
      background-color: var(--light-02);
    }

    .e-time-cells-wrap {
      table td {
        font-size: 14px;
        height: 40px;
      }
    }

    .e-current-timeline {
      border-top: 2px solid var(--tertiary-100);
    }

    .e-day-wrapper {
      .e-appointment {
        border-radius: 12px;
        padding: 0 8px 8px;
        min-height: 50px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          cursor: pointer;
        }

        &.e-appointment-border {
          box-shadow: none;
        }
      }
    }

    .template-wrap {
      .subject {
        font-weight: 600;
        margin-top: 4px;
        font-size: 16px;
        margin-bottom: 4px;
      }

      .event-description {
        margin: 12px 0 16px;
        font-weight: 500;
      }
    }
  }

  .e-vertical-view .e-header-cells {
    padding: unset;
    display: none;
  }

  .e-vertical-view .e-date-header-wrap table tbody td.e-resource-cells {
    border-bottom: unset;
    align-content: baseline;
  }

  .e-vertical-view .e-date-header-wrap table col,
  .e-vertical-view .e-content-wrap table col {
    width: 280px;
  }

  .e-month-view .e-work-cells,
  .e-month-view .e-date-header-wrap table col {
    width: 280px;
  }

  .e-schedule-toolbar {
    .e-toolbar-items {
      .e-toolbar-item {
        .e-tbar-btn {
          &:focus {
            background-color: transparent;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }

    }
  }

  &.e-device {
    .e-vertical-view {
      .e-left-indent {
        width: 85px;
      }

      .e-time-cells-wrap {
        width: 85px;
      }
    }

    .e-resource-tree-popup {
      .e-fullrow {
        height: 50px;
      }
    }

    .template-wrap {
      .resource-details {
        .resource-designation {
          display: none;
        }
      }
    }
  }
}

.e-tooltip-wrap {
  &.e-popup {
    background-color: var(--white);
    border: 1px solid var(--white);
    opacity: 1;
    border-radius: 12px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .e-tip-content {
    color: var(--black);
  }
}

.control-wrapper {
  &.loading {
    background-color: var(--light-02);
    opacity: 0.1;
  }
}

.control-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.event-sms {
  position: absolute;
  right: 0;
  right: 8px;
  top: 12px;
}

.error-sms-message {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: var(--warning);
  text-wrap: auto;
  margin-left: 4px;

  @include device(smallOnly) {
    font-size: 12px;
  }
}

.event-status {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 16px;
}

.event-temperature {
  font-weight: 600;
}

.action {
  position: fixed;
  bottom: 30px;
  right: 30px;

  &ActionIcon {
    margin-right: 10px;
  }
}

